import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../components/Login/Login';
import * as styles from '../articles.module.scss';
import ArticleImg from '../../../../assets/IMG/freebies/Grievances/1.png';
import articlePDF from "../../../../assets/pdfs/grievances.pdf";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_BaseURL } from '../../../../Services/ApiRoutes';
import { Link } from 'gatsby';

interface Props {
  location: string;
}

const GrievancesArticle: React.FC<Props> = ({ location }) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Articles/Grievances` : "";
  const scroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Articles :: BACK TO THE OFFICE?</title><meta charSet='utf-8' />
        <meta property="og:description" content="Since the pandemic began in March
            2020, it’s safe to say that tensions have
            run high at times. We were thrown into
            a state of confusion and uncertainty,
            with little or no notice, and that has
            had a huge impact on our lives." />
        <meta property="og:image" content={ArticleImg} />
        <meta property="og:title" content="PrimeHR :: BACK TO THE OFFICE?" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content="https://primehr.co.uk/Articles/Grievances" />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Articles", Text: "Articles" },
        { To: "", Text: "Grievances" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>BACK TO THE OFFICE?</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            {/* <span>Written by <Link to="https://www.linkedin.com/in/nikki-hufton-bsc-hons-mcipd-fcmi-mnlp-a7528854/">Nikki Hufton</Link></span> */}
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={ArticleImg} />
          </div>

          <h2>GRIEVANCES ARE DRAMATICALLY RISING</h2>

          <ul>
            <li><a onClick={() => scroll(firstRef)}>Making a Complaint</a></li>
            <li><a onClick={() => scroll(secondRef)}>Here Comes a Grievance</a></li>
            <li><a onClick={() => scroll(thirdRef)}>Appeals</a></li>
            <li><a onClick={() => scroll(fourthRef)}>There's a Lot to Consider</a></li>
            <li><a onClick={() => scroll(fifthRef)}>Need Some Expert HR Advice?</a></li>
          </ul>

          <p>
            Since the pandemic began in March
            2020, it’s safe to say that tensions have
            run high at times. We were thrown into
            a state of confusion and uncertainty,
            with little or no notice, and that has
            had a huge impact on our lives.
          </p>
          <p>
            One of the big changes many of us
            experienced was the sudden move to
            working from home. After the initial
            excitement of working in our pyjamas
            wore off, we hit a wall of frustration,
            stress and, for many of us, isolation.
            No matter how many Zoom meetings
            we attended, it was no substitute for
            our usual office environment.
          </p>
          <p>
            And while many of us flourished when
            given the opportunity to demonstrate
            our self-motivation, others struggled.
            And the result of that has been that
            more work issues have been reported.
            Since the beginning of the pandemic,
            48% of employees reported a work
            issue to HR. A huge 44% of employees
            also received disciplinary action as a
            result of their working from home
            behaviour.
          </p>
          <p>
            Tension has been at an all-time high.
            And, while we’re slowly getting back to
            the office environment or new hybridworking policies have started, it seems
            like we’ve brought the stress along for
            the ride.
          </p>
          <p>
            The most common complaints
            employers are seeing lately regard
            <b>employee disputes, employee
              benefits, safety at work, denial of
              time off and issues with
              equipment when working from
              home.</b>
          </p>
          <p>
            The good news is that many of these
            issues can be resolved quickly and
            easily, if the right steps are taken. It
            can be as simple as an informal chat
            with a manager.
          </p>
          <p>
            However, it’s <b>vital that the correct
              procedure is followed</b> when any
            dispute or complaint is raised. If it isn’t,
            you’re putting your business at risk. If
            not handled correctly, complaints
            could eventually end up as tribunals,
            costing you lots of time and money,
            not to mention the emotional stress it
            can bring to everyone involved.
          </p>
          <p>
            To make sure you’re protecting both
            your employees and your business,
            make sure everyone knows your policy
            on complaints and disputes and follow
            them to the letter should an issue
            arise.
          </p>
          <p>
            Policies are there for a reason, to
            protect your business!
          </p>

          <h3 ref={firstRef}>MAKING A COMPLAINT</h3>
          <p>
            Your people should be encouraged to raise any concerns they have with either their
            line manager or your company’s HR manager. This doesn’t have to be in writing in the
            first instance; a quick chat will suffice to raise an issue informally.
          </p>
          <p>
            Once an issue has been raised, it’s really important that you take it seriously. This can
            protect you from a tribunal, but it also helps to maintain good, trusting working
            relationships, which is vital for any successful business.
          </p>
          <p>
            Set up a chat with the employee who has raised an issue. Remember, <b>they are
              entitled to bring someone along</b>, however they cannot answer on the employees
            behalf, but can sum up and explain the issue which can be helpful if it is a difficult
            situation for the employee to describe. If they’re unaware of this, explain it to them
            clearly. This person is there to act as a witness for your employee; it could be a
            colleague, a trade union representative, or someone else relevant to their role. If they
            request that a friend or family member attends, you are within your rights to refuse.
            They can also bring along someone for support, if necessary, like an interpreter or carer
            and this would be considered a reasonable adjustment in the eyes of the law.
          </p>
          <p>
            At this meeting, ask them to clearly explain their issue to you and have them suggest
            how they’d like the problem to be resolved. For example, if they are having a problem
            with a colleague, they may like to sit and have a conversation with a manager present to
            act as a mediator. Or, if they’re struggling with the technology they’ve been given to
            allow them to work from home, they may suggest a new laptop to help them complete
            their role more effectively.
          </p>
          <p>
            This meeting should be a two-way process, so while it’s important that you listen closely
            to their issue and proposed solution, they should also be prepared to listen to any
            input you have on the situation, too. You may be able to suggest alternative solutions;
            then it’s up to you both to work together to decide on an agreed plan of action.
          </p>
          <p>
            You must keep a record of your meeting, even if it’s informal. Include details of the
            problem, the action you took when the problem was raised (a meeting, for example),
            what was discussed, the next steps agreed, and the reasons for the agreed steps.
          </p>
          <p>
            Agree on a follow-up meeting for when the agreed action has been taken. At this
            meeting, you can check that any steps have been actioned as agreed and look to see if
            they have resolved the issue.
          </p>
          <p>
            Hopefully, at this point, you will be in a position to close the case, so to speak. However,
            in some cases, the agreed actions don’t resolve the problem and it is necessary to take
            further action.
          </p>

          <h3 ref={secondRef}>HERE COMES A GRIEVANCE</h3>
          <p>
            At this stage, you may want to discuss
            other actions which could help to
            resolve the issue, or you may plan more
            discussions to work through the
            problem. It’s important, at this point, that
            you remind your employee that they are
            able to raise a grievance, if they feel the
            informal approach isn’t working.
          </p>
          <p>
            It’s worth noting that, if the issue at hand
            is of a more serious nature (sexual
            harassment for example), it may be
            appropriate to skip the informal steps
            and move straight to a grievance
            procedure.
          </p>
          <p>
            If a grievance is to be raised, it’s
            important that it’s done as soon as
            possible and in writing. Your company
            policy should contain all of the
            information your employees need to
            help them raise a grievance, including
            who it should be raised with.
          </p>
          <p>
            Once a grievance has been raised, you,
            as the employer, should carry out a
            formal investigation to obtain any
            relevant information. <b>The person
              carrying out the investigation
              should be fully trained and have had
              no personal involvement in the
              matter at hand</b>. Often this part of the
            process can be outsourced. It’s also
            crucial that every step of the grievance is
            handled with confidentiality; this
            includes informing any witnesses that
            they are not to discuss the matter with
            any colleagues.
          </p>
          <p>
            And again, you should keep a full record
            of anything discussed or discovered
            during your investigation.
          </p>
          <p>
            After a full investigation, you will need to
            hold a hearing. Ideally, this should be
            within 5 days. This should give your
            employee the opportunity to explain
            their side in detail and, again, set out the
            outcome they would like to see. Just like
            in an informal meeting, your employees
            are also entitled to be accompanied to a
            grievance hearing by a colleague, union
            representative, or someone else
            relevant, as well as an interpreter or
            carer, where necessary.
          </p>
          <p>
            After your hearing, you may be required
            to carry out further investigations, or you
            may be able to come to a conclusion.
          </p>
          <p>
            Any decision you do make following a
            hearing must be communicated to your
            employee, in writing, as soon as
            possible. They should also be informed
            of their right to appeal your decision.
          </p>
          <p>
            Remember that depending on the
            outcome of the investigation and/or
            your conclusion of the grievance, this
            could instigate separate proceedings
            under your Disciplinary policy.
          </p>

          <h3 ref={thirdRef}>APPEALS</h3>

          <p>
            If your employee isn’t satisfied with the
            outcome of their grievance, they have
            the right to appeal. If they do, you must
            hear their appeal without unreasonable
            delay.
          </p>
          <p>
            This works in a similar way to the initial
            grievance. You should arrange a
            meeting, and your employee has the
            right to be accompanied again.
          </p>
          <p>
            However, this meeting should be held by
            someone who wasn’t involved in the
            initial grievance. Ideally, this meeting
            should be held by someone in a more
            senior position; however, in small
            businesses, this isn’t always possible. In
            these cases, it’s sometimes appropriate
            for someone to be brought in from
            outside the business to conduct the
            meeting. For example, an external HR
            company.
          </p>
          <p>
            Your employee should be given the
            opportunity to explain why they disagree
            with your decision and what they think
            the outcome should be. It will then be
            up to the person hearing the appeal to
            decide whether it was handled correctly
            in the first instance, and where
            necessary, to decide on a new outcome.
          </p>
          <p>
            If it’s not possible to resolve an issue or
            dispute, it may be that you consider
            involving a third party to help. This could
            be in the form of a mediator, for
            example.
          </p>

          <h3 ref={fourthRef}>THERE’S A LOT TO CONSIDER</h3>

          <p>
            That may seem like a lot to think about when an
            employee raises a complaint in your business but,
            this is a very brief overview. There can be a lot more
            to consider when a grievance is raised, depending on
            the nature of the complaint.
          </p>
          <p>
            What is vital is that you follow protocol to the letter to
            avoid any unnecessary escalation. Take any complaint
            seriously and ensure you don’t miss any steps, even if
            you think they are unimportant.
          </p>
          <p>
            As mentioned earlier, fortunately, the majority of
            issues raised can be dealt with quickly and with little
            stress, providing you and your managers really listen
            to your employees. It is also possible to avoid issues
            occurring in the first place, by tackling any problems
            you see immediately and looking out for signs of
            conflict or dissatisfaction.
          </p>
          <p>
            One of the most important takeaways is that <b>you
              shouldn’t try to handle the grievance process on
              your own</b>, if you don’t have a qualified HR expert on
            hand. If handled incorrectly you could end up making
            any problem a lot worse, which could eventually land
            your business in a tribunal unnecessarily.
          </p>

          <h3 ref={fifthRef}>NEED SOME EXPERT HR ADVICE?</h3>

          <p>
            If you’re dealing with more employee issues or
            complaints lately, we’d love to help you resolve them
            before they reach a grievance. Simply give us a call or
            drop us an email to arrange a conversation.
          </p>
          <p>
            Simply contact our team on <a href='tel: 01158372772'>01158 372 772</a> or sign up
            for our legally compliant <b>Grievance Procedure</b> and <b>Guidance notes</b>.
          </p>
          <p>
            We're here to help your small business grow! And
            here when you need us most.
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.Download}>
            <a download href={articlePDF}>
              <i>
                <FontAwesomeIcon icon={faCloudDownloadAlt} />
              </i>
              Download Article
            </a>
          </div>

          <div className={styles.SectionSideInfo}>
            <h4>Found This Article Useful?</h4>
            <p>If you found this article or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

            <div className={styles.Download}>
              <Link to="/Register">
                Create an Account
              </Link>
            </div>
          </div>

          <h4><span>Or</span></h4>

          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default GrievancesArticle;
