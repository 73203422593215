import React from 'react';
import Layout from '../../components/Layout/layout';
import GrievancesArticle from '../../Views/Website/Articles/Grievances/Grievances';

const GrievancesArticlePage: React.FC = (props: any) => {
  return (
    <Layout>
      <GrievancesArticle location={props.location.href} />
    </Layout>
  );
};

export default GrievancesArticlePage;
